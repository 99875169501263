import moment from "moment";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
const PolygonListTable = ({
  polygons = [],
  onDelete = () => {},
  onEdit = () => {},
  onAdd = () => {},
  onView = () => {},
}) => {
  return (
    <div className="">
      <div className="d-flex justify-content-between mb-4">
        <p className="fs-24 fw-400 text-black mb-0">Polygon List</p>
        <button className="btn fribe-primary-btn" onClick={onAdd}>
          Add
        </button>
      </div>
      {polygons.length ? (
        <table className="table">
          <thead>
            <tr>
              <th className="user-header-item">Name</th>
              <th className="user-header-item">Name2</th>
              <th className="user-header-item">Formatted Address</th>
              <th className="user-header-item">API Channel</th>
              <th className="user-header-item header-item-center">createdAt</th>
              <th className="user-header-item header-item-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {polygons.map((item) => {
              const {
                _id: id,
                name,
                name2,
                formattedAddress,
                createdAt,
                location,
                pivotPoint,
                type,
                apiChannel,
              } = item ?? {};
              return (
                <tr className="data-row-verticle-middle" key={id}>
                  <td className="user-data-item data-item-captilize text-nowrap">
                    {name}
                  </td>
                  <td className="user-data-item data-item-captilize text-nowrap">
                    {name2}
                  </td>
                  <td className="user-data-item">{formattedAddress}</td>
                  <td className="user-data-item">{apiChannel}</td>
                  <td className="user-data-item data-item-center">
                    {moment(createdAt).format("MMM D, YYYY [at] HH:MM A")}
                  </td>
                  <td className="user-data-item data-item-center ">
                    <button
                      className="btn"
                      onClick={() => {
                        const data = {
                          name,
                          name2,
                          formattedAddress,
                          location,
                          type,
                          pivotPoint,
                          apiChannel,
                        };
                        onView(id, data);
                      }}
                    >
                      <FiEye />
                    </button>
                    <button
                      className="btn"
                      onClick={() => {
                        const data = {
                          name,
                          name2,
                          formattedAddress,
                          location,
                          type,
                          pivotPoint,
                          apiChannel,
                        };
                        onEdit(id, data);
                      }}
                    >
                      <MdEdit />
                    </button>
                    <button
                      className="btn"
                      onClick={() => {
                        onDelete(id);
                      }}
                    >
                      <AiFillDelete />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No matching result found</div>
      )}
    </div>
  );
};

export default PolygonListTable;
